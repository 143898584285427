import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  ImageList,
  ImageListItem,
  Modal,
  Typography,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import CreateProduct from "../Products/CreateProduct/CreateProduct";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteProductAction,
  fetchProductsAction,
} from "../../redux/slices/products/productSlices";
import { baseUrl } from "../../utils/baseUrl";
import NavbarOther from "../Navbar/PublicOther/NavbarOther";
import galeri1 from "../../assests/galeri/galeri1.jpg";
import galeri2 from "../../assests/galeri/galeri2.jpg";
import galeri3 from "../../assests/galeri/galeri3.jpg";
import galeri4 from "../../assests/galeri/galeri4.jpg";
import galeri5 from "../../assests/galeri/galeri5.jpg";
import galeri6 from "../../assests/galeri/galeri6.JPG";
import galeri7 from "../../assests/galeri/galeri7.jpg";
import galeri8 from "../../assests/galeri/galeri8.jpg";
import galeri9 from "../../assests/galeri/galeri9.jpg";
import galeri10 from "../../assests/galeri/galeri10.JPG";
import galeri11 from "../../assests/galeri/galeri11.jpg";
import galeri12 from "../../assests/galeri/galeri12.jpg";
import galeri13 from "../../assests/galeri/galeri13.jpg";
import galeri14 from "../../assests/galeri/galeri14.JPG";
import galeri15 from "../../assests/galeri/galeri15.jpg";
import galeri16 from "../../assests/galeri/galeri16.JPG";
import galeri17 from "../../assests/galeri/galeri17.jpg";
import galeri18 from "../../assests/galeri/galeri18.jpg";
import galeri19 from "../../assests/galeri/galeri19.jpg";
import galeri20 from "../../assests/galeri/galeri20.JPG";
import galeri21 from "../../assests/galeri/galeri21.JPG";

const photoData = [
  {
    img: galeri1,
    title: "Photo 1",
  },
  {
    img: galeri2,
    title: "Photo 2",
  },
  {
    img: galeri3,
    title: "Photo 3",
  },
  {
    img: galeri8,
    title: "Photo 6",
  },
  {
    img: galeri9,
    title: "Photo 6",
  },
  {
    img: galeri10,
    title: "Photo 6",
  },
  {
    img: galeri11,
    title: "Photo 6",
  },
  {
    img: galeri12,
    title: "Photo 6",
  },
  {
    img: galeri13,
    title: "Photo 6",
  },
  {
    img: galeri14,
    title: "Photo 6",
  },
  {
    img: galeri15,
    title: "Photo 6",
  },
  {
    img: galeri16,
    title: "Photo 6",
  },
  {
    img: galeri4,
    title: "Photo 4",
  },
  {
    img: galeri5,
    title: "Photo 5",
  },
  {
    img: galeri6,
    title: "Photo 6",
  },
  {
    img: galeri7,
    title: "Photo 6",
  },

  {
    img: galeri17,
    title: "Photo 6",
  },
  {
    img: galeri18,
    title: "Photo 6",
  },
  {
    img: galeri19,
    title: "Photo 6",
  },
  {
    img: galeri20,
    title: "Photo 6",
  },
  {
    img: galeri21,
    title: "Photo 6",
  },
];

const Galeri = () => {
  const [open, setOpen] = useState(false);
  const [selectedMedia, setSelectedMedia] = useState(null);
  const [selectedPhoto, setSelectedPhoto] = useState(null);
  const product = useSelector((state) => state?.product);
  const { productLists, appErr, serverErr, isDeleted } = product;
  const state = useSelector((state) => state.users);
  const { userAuth } = state;
  console.log(productLists);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchProductsAction(""));
  }, [dispatch, isDeleted]);

  const mediaItems = productLists?.map((item) => ({
    type: item.image.endsWith(".mp4") ? "video" : "image", // Format belirleme
    src: `${baseUrl}/product/${item.image}`,
    alt: item.title || "Görsel veya video", // Başlık yoksa varsayılan
    id: item._id,
  }));

  const handleClick = (media) => {
    if (media) {
      setSelectedMedia(media);
      setOpen(true);
    }
  };

  const handleOpen = (photo) => {
    setSelectedPhoto(photo);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedPhoto(null);
  };

  const [fadeIn, setFadeIn] = useState(false);

  useEffect(() => {
    setFadeIn(true);
  }, []);

  // return (
  //   <>
  //     <NavbarOther />
  //     <div className="container mx-auto px-4 pt-8">
  //       <h1
  //         style={{
  //           fontFamily: "'Dancing Script', cursive",
  //           fontSize: "2.25rem",
  //           fontWeight: "800",
  //           textAlign: "center",
  //           color: "rgb(75 85 99)",
  //           marginBottom: "1rem",
  //           transition: "all 1s",
  //           opacity: fadeIn ? "1" : "0",
  //           transform: fadeIn ? "translateY(0)" : "translateY(2.5rem)",
  //         }}
  //       >
  //         Galeri
  //       </h1>
  //     </div>
  //     {userAuth ? <CreateProduct /> : null}
  //     <div
  //       style={{
  //         display: "flex",
  //         flexDirection: "column",
  //         alignItems: "center",
  //         margin: "0 auto",
  //         padding: "20px",
  //         width: "90%",
  //       }}
  //     >
  //       <div
  //         style={{
  //           display: "flex",
  //           flexWrap: "wrap",
  //           gap: "16px",
  //           justifyContent: "center",
  //         }}
  //       >
  //         {mediaItems?.map((item, index) => (
  //           <Card
  //             key={index}
  //             className={`${
  //               fadeIn
  //                 ? "opacity-100 translate-y-0"
  //                 : "opacity-0 translate-y-10"
  //             } card-animation`}
  //             style={{
  //               width: "200px",
  //               cursor: "pointer",
  //               boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  //               borderRadius: "8px",
  //               overflow: "hidden",
  //               transition: "opacity 0.5s ease, transform 0.5s ease",
  //             }}
  //             onClick={() => handleClick(item)}
  //           >
  //             <CardContent style={{ padding: "0" }}>
  //               {item.type === "image" ? (
  //                 <img
  //                   src={item.src}
  //                   alt={item.alt}
  //                   style={{
  //                     width: "100%",
  //                     height: "200px",
  //                     objectFit: "cover",
  //                     display: "block",
  //                   }}
  //                 />
  //               ) : (
  //                 <video
  //                   width="100%"
  //                   height="200"
  //                   controls
  //                   style={{ display: "block" }}
  //                 >
  //                   <source src={item.src} type="video/mp4" />
  //                   Your browser does not support the video tag.
  //                 </video>
  //               )}
  //             </CardContent>
  //             {userAuth ? (
  //               <div className="flex justify-center items-center">
  //                 <Button
  //                   onClick={(event) => {
  //                     event.stopPropagation(); // Olayı durdur
  //                     dispatch(deleteProductAction(item.id)); // Ürünü sil
  //                   }}
  //                 >
  //                   Sil
  //                 </Button>
  //               </div>
  //             ) : null}
  //           </Card>
  //         ))}
  //       </div>

  //       <Dialog
  //         open={open && selectedMedia !== null}
  //         onClose={handleClose}
  //         maxWidth="md"
  //         fullWidth
  //       >
  //         <DialogTitle>
  //           <IconButton
  //             edge="end"
  //             color="inherit"
  //             onClick={handleClose}
  //             aria-label="close"
  //             style={{ position: "absolute", right: "10px", top: "10px" }}
  //           >
  //             <CloseIcon />
  //           </IconButton>
  //         </DialogTitle>
  //         <DialogContent>
  //           {selectedMedia ? (
  //             selectedMedia.type === "image" ? (
  //               <img
  //                 src={selectedMedia.src}
  //                 alt={selectedMedia.alt}
  //                 style={{
  //                   width: "100%",
  //                   height: "auto",
  //                   objectFit: "contain",
  //                 }}
  //               />
  //             ) : (
  //               <video width="100%" controls>
  //                 <source src={selectedMedia.src} type="video/mp4" />
  //                 Your browser does not support the video tag.
  //               </video>
  //             )
  //           ) : (
  //             <Typography variant="body1">Medya yükleniyor...</Typography>
  //           )}
  //         </DialogContent>
  //       </Dialog>
  //     </div>
  //   </>
  // );

  return (
    <>
      <NavbarOther />
      <div className="container mx-auto px-4 pt-8">
      <h1
           style={{
            fontFamily: "'Dancing Script', cursive",
            fontSize: "2.25rem",
            fontWeight: "800",
            textAlign: "center",
             color: "rgb(75 85 99)",
            marginBottom: "1rem",
            transition: "all 1s",
            opacity: fadeIn ? "1" : "0",
            transform: fadeIn ? "translateY(0)" : "translateY(2.5rem)",
           }}
        >
           Galeri
       </h1>
       </div>
      <Box
        sx={{
          width: "90%",
          margin: "auto",
          padding: 2,
        }}
      >
        <ImageList
          variant="masonry"
          cols={3} // Default column count
          gap={8} // Space between images
          sx={{
            "@media (max-width: 960px)": { cols: 2 }, // Tablet view
            "@media (max-width: 600px)": { cols: 1 }, // Mobile view
          }}
        >
          {photoData.map((item, index) => (
            <ImageListItem key={index} onClick={() => handleOpen(item.img)}>
              <img
                src={`${item.img}?w=248&fit=crop&auto=format`}
                srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                alt={item.title}
                loading="lazy"
                style={{
                  borderRadius: "8px",
                  boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
                }}
              />
            </ImageListItem>
          ))}
        </ImageList>
        <Modal open={open} onClose={handleClose}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "80%",
              maxWidth: 800,
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
              borderRadius: "8px",
              outline: "none",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: 2, // İçerikler arasına boşluk ekler
            }}
          >
            {selectedPhoto && (
              <img
                src={selectedPhoto}
                alt="Selected"
                style={{
                  width: "100%",
                  height: "auto",
                  borderRadius: "8px",
                }}
              />
            )}
            <Button
              variant="contained"
              color="secondary"
              onClick={handleClose}
              sx={{ alignSelf: "flex-end" }} // Sağ üste hizalama
            >
              Kapat
            </Button>
          </Box>
        </Modal>
      </Box>
    </>
  );
};

export default Galeri;
