import React, { useEffect, useState } from "react";
import { Box, Card, CardContent, CardMedia, Typography } from "@mui/material";
import simge from "../../assests/hakkimizda/simge.webp";
import Yorumlar from "../YorumlarPage/Yorumlar";
import NavbarOther from "../Navbar/PublicOther/NavbarOther";
const About = () => {
  const [fadeIn, setFadeIn] = useState(false);

  useEffect(() => {
    setFadeIn(true);
  }, []);

  return (
    <>
      <NavbarOther />
      <div className="py-4"></div>
      <h1
        className={`text-4xl font-extrabold text-center text-pink-600 mb-4  transition-all duration-1000 ${
          fadeIn ? "opacity-100 translate-y-0" : "opacity-0 translate-y-10"
        }`}
      >
        Hakkımızda
      </h1>
      <Box
        sx={{
          display: "flex",
          width: "80%",
          margin: "auto",
          marginBottom: 3,
          justifyContent: "center",
          opacity: fadeIn ? 1 : 0, // Opacity değerini fadeIn değişkenine göre ayarla
          transform: fadeIn ? "translateY(0)" : "translateY(10px)", // Y ekseninde hareket
          transition: "opacity 0.5s ease, transform 0.5s ease",
        }}
      >
        <Typography
          sx={{
            fontStyle: "italic", // Yazıyı italik yapar
            color: "#6baed6", // Bebek mavisi rengi (isteğe bağlı olarak hex kodu değiştirilebilir)
            textAlign: "center", // Ortalanmış görünüm (isteğe bağlı)
            fontSize: "1.2rem", // Boyut ayarı (isteğe bağlı)
          }}
        >
          Her yaşınız bizimle başlar bizimle biter...
        </Typography>
      </Box>
      <Card
        sx={{
          display: "flex",
          flexDirection: { xs: "column-reverse", sm: "row" }, // Mobilde column-reverse, büyük ekranlarda row
          width: "80%",
          margin: "auto",
          marginBottom: 3,
          opacity: fadeIn ? 1 : 0, // Opacity değerini fadeIn değişkenine göre ayarla
          transform: fadeIn ? "translateY(0)" : "translateY(10px)", // Y ekseninde hareket
          transition: "opacity 0.5s ease, transform 0.5s ease",
          marginTop: 4,
          boxShadow: 10, // Geçiş efekti için zamanlama
          "&:hover": {
            transform: "scale(1.05)", // Kartın biraz büyüyerek öne çıkmasını sağlar
            boxShadow: 12, // Hover sırasında daha belirgin bir gölge efekti ekler
          },
        }}
      >
        {/* Sağ Tarafta Fotoğraf */}
        <CardMedia
          component="img"
          alt="Fotoğraf"
          image={simge}
          sx={{
            width: { xs: "100%", sm: "50%" }, // Fotoğrafın genişliği yüzde 50
            height: 400, // Yüksekliğini otomatik ayarla
            objectFit: "cover", // Fotoğrafı kapsayıcıya sığdır
            display: "block", // Görseli blok eleman yap, ortalama için
            margin: "auto", // Fotoğrafı ortala
          }}
        />

        {/* Sol Tarafta Yazı */}
        <CardContent
          sx={{
            flex: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            opacity: fadeIn ? 1 : 0, // Opacity değerini fadeIn değişkenine göre ayarla
            transform: fadeIn ? "translateY(0)" : "translateY(10px)", // Y ekseninde hareket
            transition: "opacity 0.5s ease, transform 0.5s ease",
          }}
        >
          <Typography variant="body2" color="text.secondary">
            Hayatınızın en özel anlarını unutulmaz kılmak için buradayız! Simge
            Büyükgüzel Party House, doğum günleri, baby shower’lar, konsept
            etkinlikler ve daha pek çok özel etkinliği düzenleyerek her anı
            sizin için eşsiz kılmayı misyon edinmiş bir organizasyon firmasıdır.
            Yılların verdiği tecrübe ve yaratıcı yaklaşımımızla, her detayda
            mükemmeliyet arayışımız sayesinde misafirlerimize ve sizlere keyifli
            anılar bırakmayı hedefliyoruz.<br></br>
            Profesyonel ekibimiz, etkinliğinizin her aşamasında yanınızda olacak
            ve sadece bir organizasyon değil, hayatınız boyunca hatırlayacağınız
            anlar yaratacak bir deneyim sunacaktır. İster sıcak bir doğum günü
            kutlaması, ister neşeli bir baby shower, ya da özel bir sürpriz
            parti... Simge Büyükgüzel Party House ile her etkinlik, sizin
            tarzınıza ve isteğinize uygun olarak şekillendirilir. <br></br>
            Biz, hayalinizdeki etkinlikleri gerçeğe dönüştüren, size sadece anın
            tadını çıkarmak kalacak profesyonelleriz. Siz sadece gülümseyin;
            gerisini bize bırakın!
          </Typography>
        </CardContent>
      </Card>
      <Card
        sx={{
          display: "flex",
          flexDirection: { xs: "column-reverse", sm: "row" }, // Mobilde column-reverse, büyük ekranlarda row
          width: "80%",
          margin: "auto",
          marginBottom: 3,
          marginTop: 4,
          boxShadow: 10,
          transition: "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out", // Geçiş efekti için zamanlama
          "&:hover": {
            transform: "scale(1.05)", // Kartın biraz büyüyerek öne çıkmasını sağlar
            boxShadow: 12, // Hover sırasında daha belirgin bir gölge efekti ekler
          },
        }}
      >
        <CardContent
          sx={{
            flex: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography variant="body2" color="text.secondary">
            Ankara’nın kalbinde, şehir hayatının yoğunluğundan uzak, sizlere
            özel anlar yaratmayı hedefleyen Simge Büyükgüzel Party House, her
            etkinliği kusursuzca planlayıp hayata geçirerek misafirlerinizi
            etkileyici bir deneyime davet eder. Geniş mekanımız ve modern
            olanaklarımızla, aradığınız rahatlık ve şıklığı bir arada sunuyoruz.
            Özel etkinlikleriniz için titizlikle hazırlanan alanlarımızda, her
            ayrıntı düşünülerek konforunuz ve memnuniyetiniz ön planda tutulur.
            <br></br>
            Simge Büyükgüzel Party House olarak, Ankara'nın en güzel
            köşelerinden ilham alıyor ve organizasyonlarınıza şehrin canlı
            ruhunu katıyoruz. Doğum günleri, baby shower’lar, nişanlar, özel
            kutlamalar ve daha pek çok etkinlikte, sizleri ve sevdiklerinizi
            ağırlamaktan mutluluk duyuyoruz. İleriye dönük anılarınızı
            güzelleştirecek her etkinlik, bizim için bir tutkudur; işimizi
            sevgiyle ve özenle yapıyoruz.
          </Typography>
        </CardContent>
        {/* Sağ Tarafta Fotoğraf */}
        <Box
          sx={{
            width: { xs: "100%", sm: "50%" }, // iframe genişliği
            height: 400, // iframe yüksekliği
            display: "block",
            margin: "auto", // iframe'i ortala
            overflow: "hidden", // Taşmayı engelle
          }}
        >
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4330.673295050727!2d32.67524743025367!3d39.86867230805909!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14d338c8c84d0bfd%3A0x843b2bf13d5d9c3!2zU2ltZ2UgQsO8ecO8a2fDvHplbCBQYXJ0eSBIb3VzZQ!5e0!3m2!1str!2str!4v1733054181734!5m2!1str!2str"
            width="100%"
            height="400px"
            style={{ border: 0 }}
            allowFullScreen
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            title="map"
          ></iframe>
        </Box>
      </Card>
      <Yorumlar />
    </>
  );
};

export default About;
