import React, { useEffect, useState } from "react";
import {
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Typography,
  Grid,
  Box,
} from "@mui/material";
import Yorumlar from "../YorumlarPage/Yorumlar";
import yetiskin from "../../assests/hizmetlerPage/yetiskin.webp";
import happy from "../../assests/hizmetlerPage/happy.webp";
import konsept from "../../assests/hizmetlerPage/konsept.webp";
import cinsiyet from "../../assests/hizmetlerPage/cinsiyet.webp";
import catering from "../../assests/hizmetlerPage/catering.webp";
import kisi from "../../assests/hizmetlerPage/kisi.webp";
import foto from "../../assests/hizmetlerPage/foto.webp";
import video from "../../assests/hizmetlerPage/video.webp";
import NavbarOther from "../Navbar/PublicOther/NavbarOther";
const cardData = [
  {
    title: "Doğum Günü",
    description:
      "Doğum günleri, sevdiklerinizle birlikte kutlayacağınız en özel anlardan biridir. Bu unutulmaz günü daha da özel kılmak için profesyonel bir dokunuşa mı ihtiyacınız var? Biz buradayız! Hayal ettiğiniz temayı gerçeğe dönüştürmek, benzersiz süslemelerle unutulmaz bir atmosfer yaratmak ve davetlileriniz için keyif dolu bir deneyim sunmak için her detayı sizin yerinize planlıyoruz.",
    image: happy,
  },
  {
    title: "Konsept Parti",
    description:
      "Bir kutlama düşünün, her detayı size özel ve tamamen hayalinizdeki gibi... Konsept partilerimizle sıradan bir günü bile unutulmaz bir deneyime dönüştürüyoruz! Küçük detaylardan büyük anlara kadar her şeyi sizin için planlıyoruz. Davetlileriniz bu özel günün konuşulmaya değer olduğunu söyleyecek!",
    image: konsept,
  },
  {
    title: "Yetişkinlere Özel Partiler",
    description:
      "Kendinizi günlük hayatın yoğunluğundan bir an olsun uzaklaştırmak ve doyasıya eğlenmek ister misiniz? Yetişkinlere özel partilerimiz tam size göre! Şıklığın ve keyfin bir araya geldiği, arkadaşlarınızla ya da sevdiklerinizle unutulmaz anlar yaşayabileceğiniz benzersiz bir atmosfer sizi bekliyor.",
    image: yetiskin,
  },
  {
    title: "Cinsiyet Partisi",
    description:
      "Bebeğinizin cinsiyetini öğrenmenin bu özel anını, sevdiklerinizle paylaşacağınız unutulmaz bir kutlamaya dönüştürün! Cinsiyet partileri, mutluluğunuzu en yaratıcı ve eğlenceli şekilde kutlamanız için harika bir fırsat.",
    image: cinsiyet,
  },
  {
    title: "Tema Seçimi ve Catering Hizmetleri",
    description:
      "Her özel anı daha da unutulmaz kılmak için doğru tema ve lezzetli ikramlar büyük önem taşır. Biz, etkinliğinize özgü bir atmosfer yaratmak ve misafirlerinizi memnun edecek catering hizmetleri sunmak için buradayız! Hem göz alıcı bir tema hem de unutulmaz tatlar arıyorsanız, sizin için mükemmel bir organizasyon planlamaktan mutluluk duyarız.",
    image: catering,
  },
  {
    title: "Kişiye Özel Etkinlikler",
    description:
      "Her anınız özel, her hikayeniz eşsiz! Sizin için tasarlanan kişiye özel etkinliklerle, hayallerinizi gerçeğe dönüştürmeye hazırız. Hayatınızdaki bu değerli anları unutulmaz kılmak için yanınızdayız. Her anınız özel, her hikayeniz eşsiz! Sizin için tasarlanan kişiye özel etkinliklerle, hayallerinizi gerçeğe dönüştürmeye hazırız. Hayatınızdaki bu değerli anları unutulmaz kılmak için yanınızdayız.",
    image: kisi,
  },
  {
    title: "Fotoğraf Çekimi",
    description:
      "Etkinliklerinizde her anın en özel şekilde ölümsüzleşmesi için profesyonel fotoğraf çekimi hizmeti sunuyoruz. Her gülüş, her bakış ve her detay, eşsiz bir hikaye anlatır. Biz de bu hikayeyi fotoğraflarla sonsuz kılmak için buradayız.",
    image: foto,
  },
  {
    title: "Video Çekimi",
    description:
      "Her özel an, bir hikaye anlatır ve bu hikayeyi en etkileyici şekilde geleceğe taşımak için profesyonel video çekimi hizmetimizle yanınızdayız. Organizasyonlarınızın her anını, sinematik bir deneyime dönüştürmek için buradayız.",
    image: video,
  },
];

const Hizmetler = () => {
  const [fadeIn, setFadeIn] = useState(false);

  useEffect(() => {
    setFadeIn(true);
  }, []);

  const colors = [
    "#FF5733",
    "#33FF57",
    "#3357FF",
    "#FF33A6",
    "#FFA533",
    "#C70039",
    "#900C3F",
    "#8E44AD",
  ];

  return (
    <>
      <NavbarOther />
      <div className="py-4"></div>
      <h1
        style={{
          fontFamily: "'Dancing Script', cursive",
          fontSize: "2.25rem",
          fontWeight: "800",
          textAlign: "center",
          color: "rgb(75 85 99)",
          marginBottom: "1rem",
          transition: "all 1s",
          opacity: fadeIn ? "1" : "0",
          transform: fadeIn ? "translateY(0)" : "translateY(2.5rem)",
        }}
      >
        Hizmetlerimiz
      </h1>
      <Box
        sx={{
          width: "100%", // Responsive genişlik
          maxWidth: "1400px", // Maksimum genişlik
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mx: "auto",
          marginBottom: 3,
          opacity: fadeIn ? 1 : 0, // Anlamlı bir animasyon için
          transform: fadeIn ? "translateY(0)" : "translateY(20px)",
          transition: "opacity 1s, transform 1s", // Geçiş animasyonu
        }}
      >
        <Grid
          container
          spacing={4}
          sx={{
            margin: 0, // Grid'in dış kenarını sıfırlıyoruz
            width: "100%", // Grid genişliğini yüzde olarak ayarla
          }}
        >
          {cardData.map((card, index) => (
            <Grid
              item
              xs={11}
              sm={6}
              md={4}
              lg={3}
              key={index}
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Card
                sx={{
                  maxWidth: 345,
                  width: "100%", // Mobilde tam genişlik için
                  boxShadow: 5,
                  transition: "transform 0.3s ease, box-shadow 0.3s ease",
                  "&:hover": {
                    transform: "scale(1.05)",
                    boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
                  },
                }}
              >
                <CardActionArea>
                  <CardMedia
                    component="img"
                    sx={{
                      height: 200, // Sabit yükseklik
                      objectFit: "cover", // Görselleri kesmeden kapsar
                    }}
                    image={card.image}
                    alt={card.title}
                  />
                  <CardContent>
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="div"
                      sx={{
                        textAlign: "center",
                        color: colors[index % colors.length], // Dinamik renk atanması
                      }}
                    >
                      {card.title}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ color: "text.secondary" }}
                    >
                      {card.description}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>

      <Yorumlar />
    </>
  );
};
export default Hizmetler;
