import React from "react";
import PrivateNavbar from "./Private/PrivateNavbar";
import PublicNavbar from "./Public/PublicNavbar";
import { useSelector } from "react-redux";

const Navbar = ({menuOpen, setMenuOpen, toggleMenu,}) => {
  const state = useSelector((state) => state.users);
  const { userAuth } = state;

  return (
    <>
      {" "}
      <PublicNavbar menuOpen={menuOpen} setMenuOpen={setMenuOpen} toggleMenu={toggleMenu} />
    </>
  );
};

export default Navbar;
