import { useFormik } from "formik";
import * as Yup from "yup";
import Dropzone from "react-dropzone";
import { Redirect } from "react-router-dom";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { createproductAction } from "../../../redux/slices/products/productSlices";

const formSchema = Yup.object({
  image: Yup.mixed().required("Fotoğraf veya video seçimi zorunlu"),
});

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  border-color: red;
  transition: border 0.24s ease-in-out;
`;

const CreateProduct = () => {
  const dispatch = useDispatch();
  const product = useSelector((state) => state?.product);
  const { isCreated, loading, appErr, serverErr } = product;

  const formik = useFormik({
    initialValues: {
      image: null,
    },
    onSubmit: (values) => {
      const data = {
        image: values.image,
      };
      console.log(data); // Test için log
      dispatch(createproductAction(data));
    },
    validationSchema: formSchema,
  });

  if (isCreated) {
    window.location.reload(); // Sayfayı yenile
    return null; // Daha fazla render işlemini engelle
  }

  return (
    <div className="flex flex-col justify-center sm:px-6 lg:px-8">
      <div className="mt-4 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 mx-6 px-10 shadow sm:rounded-lg">
          <form onSubmit={formik.handleSubmit} className="space-y-6">
            <div>
              <label className="block text-sm font-medium mt-3 mb-2 text-gray-700">
                Lütfen fotoğraf veya video seçin
              </label>
              <Container>
                <Dropzone
                  accept="image/*,video/*"
                  onDrop={(acceptedFiles) => {
                    formik.setFieldValue("image", acceptedFiles[0]);
                  }}
                >
                  {({ getRootProps, getInputProps }) => (
                    <div {...getRootProps({ className: "dropzone" })}>
                      <input {...getInputProps()} />
                      <p className="text-gray-300 text-lg cursor-pointer hover:text-gray-500">
                        Buraya basın
                      </p>
                    </div>
                  )}
                </Dropzone>
              </Container>
              {/* Error message */}
              <div className="text-red-500">
                {formik.touched.image && formik.errors.image}
              </div>
            </div>
            <div>
              {loading ? (
                <button
                  disabled
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Lütfen bekleyin
                </button>
              ) : (
                <button
                  type="submit"
                  className="w-full flex justify-center text-white bg-gradient-to-br from-green-400 to-blue-600 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-green-200 dark:focus:ring-green-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                >
                  Yükle
                </button>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CreateProduct;
