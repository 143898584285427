import React, { useState, useEffect } from "react";
import { Card, CardContent, Typography, Rating, Box } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";

// Örnek yorum verisi
const comments = [
  {
    name: "Işıl Çekiç",
    rating: 5,
    comment:
      "Harika bir parti evi. Yemekler lezzetli, animasyon ekibi tecrübeli, fotoğrafçı başarılı. Simge hanım’a, Dinçer bey’e içten yaklaşımları için çok teşekkür ederim. Hem kızım hem biz hem de gelen herkes memnun ayrıldı.",
  },
  {
    name: "Didem Kaya",
    rating: 5,
    comment:
      "Özel günlerimizi eşsiz kıldığı için Simge’ye ve ekinine çok teşekkürler. Baştan sona işini aksatmadan özenerek yapan güvenilir bir adres bizim için. Başarılarının devamını dilerim,sevgiler",
  },
  {
    name: "Derya Esmeray Taşkın",
    rating: 5,
    comment:
      "Tam anlamıyla başından sonuna her şeyiyle yormadan, yorulmadan harika bir kutlama yaptık sayesinde. Her detayla hep kendisi ilgilendi her şeyi en iyi şekilde yaptı Simge Hanım. Bize sadece keyfini çıkarmak kaldı",
  },
  {
    name: "Ezgi Seçkiner Bingöl",
    rating: 5,
    comment:
      "Kızımızın 7 yaş doğum gününü burada kutladık, çok memnun kaldık. Güleryüzlü profesyonel ve samimi iletişim tarzlarıyla Dinçer bey ve Simge hanım işlerinde çok iyiler. İkramlar, hijyen ve servis çok iyiydi. Ekstra ücret ile gelen Sihirbaz çocukları çok güzel eğlendirdi. Herşey için çok teşekkürler.",
  },
  {
    name: "Ece Hacıosmanoğlu",
    rating: 5,
    comment:
      "Oğlumun 5 yaş doğum gününü kutlamak için gittik. Mekanı, süslemeleri biz de doğum dünü olduğu saat gördük, dincer bey her şeyi ona bırakmamızı istemişti, iyi ki de öyle yapmışız, yemekler çok lezzetliydi, çalışanlar ilgiliydi, üst katta çocuklara oyun oynatan çılgın abi, çocukları çok çok güzel idare etti, en son biz velilere de yarışma yaptı. Hem biz hem konuklarımız çok keyif aldık. Teşekkür ederiz.",
  },
  {
    name: "Çiğdem Sezgin",
    rating: 5,
    comment:
      "20 Mayis itibari ile cocuklarimizin dogumgunu kutladik. Simge Hanim işine fazlasıyla hakim, misafirleri ile kendisi tum etkinlik suresince yakından ilgileniyor. Çalışanlar gözünüzün içine bakiyor birseye ihtiyacınız var mı diye. Menüsü inanılmaz zengin ve hepsi çok lezzetli. Özellikle pasta harikaydı. Cocuklar ve yetişkinler birlikte çok keyifli vakit geçirdiler. Hersey için teşekkür ederiz",
  },
  {
    name: "Sıla Yılmaz",
    rating: 5,
    comment:
      "Organizasyona giderken çocuğunuzu sadece giydirip götürmeniz yeterli çünkü geri kalan her detay ile Simge Hanım ilgileniyor. Yemeklerin tadı muhteşem, restaurant olsa gidilir :) Her ayrılan mutlu bir ifade ile çıkıyor ve kendi partisi için organizasyon tarihi alıyor. Biz çok memnun kaldık, konuklar memnun, çocuklar memnun.. Üstüne söyleyecek söz kalmıyor :)",
  },
  {
    name: "Buğra Akyürek",
    rating: 5,
    comment:
      "Kızımızın 1 yaş doğum günü kutlamasını yaptık. İkramlar, ilgi ve sunumlar çok hoşumuza gitti. Bu tür kutlama ve etkinlikleriniz için güzel bir fırsat. Simge Hanım’a çok teşekkür ederiz.",
  },
  {
    name: "Özlem Gürbüz",
    rating: 5,
    comment:
      "Olgumun 8 yaş Dogum günü organizasyonu coook güzel oldu…simge hanım ve Dinçer bey teşekkürler sanırım ömrüm boyunca unutmayacağım bir gün yaşattınız",
  },
  {
    name: "Havva Aydın",
    rating: 5,
    comment:
      "Simge hanım’a ve tüm Simge Büyükgüzel Party House ekibine herşey için teşekkür ederim.Kesinlikle tavsiye ederim, çocuklarımız da biz de partimiz sonrası gayet mutlu ayrıldık.Herşey tek kelimeyle mükemmeldi.",
  },
  {
    name: "Yüksel Yücel",
    rating: 5,
    comment:
      "An itibariyle oğlumuzun 1 yaş Doğum gününü yaptığımız mekan her şeyiyle harikaydı. Simge hanım her detayıyla birebir kendisi ilgilendi . Bize sadece misafirlerimizle anın tadını çıkarmak ve oğlumuzla fotoğraf çektirmek kaldı . İkramların ne kadar lezzetli olduğunu personelin etrafımızda döndüğü söylemeden de geçemeyeceğim. Tekrardan çok teşekkürler.",
  },
];

const CommentCard = ({ name, rating, comment }) => {
  return (
    <Card
      sx={{
        padding: 2,
        display: "flex",
        flexDirection: "column",
        justifyContent: "start",
        alignItems: "start",
        marginBottom: 4,
        boxShadow: 5,
        marginTop: 1,
        height: 300,
        overflowY: "auto",
      }}
    >
      <CardContent>
        <Typography variant="h6" component="div" gutterBottom>
          <Box display="flex" alignItems="center">
            <PersonIcon sx={{ marginRight: 1 }} />
            {name}
          </Box>
        </Typography>
        <Box display="flex" alignItems="center">
          <Rating value={rating} readOnly precision={0.5} />
          <Typography variant="body2" sx={{ marginLeft: 1 }}>
            {rating} / 5
          </Typography>
        </Box>
        <Typography variant="body1" sx={{ marginTop: 2 }}>
          {comment}
        </Typography>
      </CardContent>
    </Card>
  );
};

const Yorumlar = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      if (currentIndex < comments.length - 4) {
        setCurrentIndex((prevIndex) => prevIndex + 4);
      } else {
        setCurrentIndex(0); // Eğer sonuna gelindiyse başa dön
      }
    }, 7000); // 3 saniyede bir kaydırma

    return () => clearInterval(interval); // Cleanup function
  }, [currentIndex]);

  const moveNext = () => {
    if (currentIndex < comments.length - 4) {
      setCurrentIndex(currentIndex + 4);
    } else {
      setCurrentIndex(0);
    }
  };

  const movePrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 4);
    } else {
      setCurrentIndex(comments.length - 4);
    }
  };

  const [justifyContent, setJustifyContent] = useState("flex-end");

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 640) {
        setJustifyContent("flex-start");
      } else {
        setJustifyContent("flex-end");
      }
    };

    // İlk başta çalıştır
    handleResize();

    // Resize event dinleyicisi
    window.addEventListener("resize", handleResize);

    // Component unmount olduğunda dinleyiciyi kaldır
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <h1
        style={{
          fontFamily: "'Dancing Script', cursive",
          fontSize: "2.25rem",
          fontWeight: "800",
          textAlign: "center",
          color: "rgb(75 85 99)",
          marginBottom: "1rem",
          transition: "all 1s",
        }}
      >
        Mutlu Müşteriler
      </h1>
      <div
        style={{
          position: "relative",
          width: "90%",
          margin: "auto",
          overflow: "hidden",
        }}
      >
        <button
          onClick={movePrev}
          style={{
            position: "absolute",
            top: "50%",
            left: "10px",
            transform: "translateY(-50%)",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            color: "white",
            border: "none",
            padding: "10px",
            cursor: "pointer",
            zIndex: 2,
          }}
        >
          ❮
        </button>
        <div
          style={{
            display: "flex",
            overflow: "hidden",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              transition: "transform 0.5s ease-in-out",
              transform: `translateX(-${
                (currentIndex / comments.length) * 100
              }%)`,
            }}
          >
            {comments.map((comment, index) => (
              <div
                key={index}
                style={{
                  flex: "0 0 23%",
                  // margin: "0 1%",
                  boxSizing: "border-box",
                }}
              >
                <CommentCard
                  name={comment.name}
                  rating={comment.rating}
                  comment={comment.comment}
                />
              </div>
            ))}
          </div>
        </div>
        <button
          onClick={moveNext}
          style={{
            position: "absolute",
            top: "50%",
            right: "10px",
            transform: "translateY(-50%)",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            color: "white",
            border: "none",
            padding: "10px",
            cursor: "pointer",
          }}
        >
          ❯
        </button>
        <div
          style={{
            display: "flex",
            alignItems: "flex-end",
            justifyContent: justifyContent,
            marginBottom: "16px",
          }}
        >
          <a
            className="text-blue-500 text-xl"
            target="_blank"
            href="https://www.google.com/search?sa=X&sca_esv=4a9e581ba0958f54&tbm=lcl&sxsrf=ADLYWIJyqy3PHu1CHTLmsUzdGvyby4kBMg:1733138520465&q=Simge+B%C3%BCy%C3%BCkg%C3%BCzel+Party+House+Yorumlar&rflfq=1&num=20&stick=H4sIAAAAAAAAAONgkxIytTQ1NTQzNLA0sTAxNjQ3NDLewMj4ilEjODM3PVXB6fCeysN7stMP76lKzVEISCwqqVTwyC8tTlWIzC8qzc1JLFrESrRSAAQ6VPpvAAAA&rldimm=595516109484317123&hl=tr-TR&ved=2ahUKEwjy--6O_IiKAxUfSPEDHXuHMt0Q9fQKegQIPxAF&biw=1536&bih=695&dpr=1.25#lkt=LocalPoiReviews"
          >
            Google yorumlarımızı görmek için tıklayınız.
          </a>
        </div>
      </div>
    </>
  );
};

export default Yorumlar;
