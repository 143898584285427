import React, { useEffect, useRef, useState } from "react";
import "./ImageSlider.css";
import baby1 from "../../assests/anasayfa/baby1.webp";
import Yorumlar from "../YorumlarPage/Yorumlar";
import Navbar from "../Navbar/Navbar";
import foto1 from "../../assests/anasayfa/foto1.avif";
import foto3 from "../../assests/anasayfa/foto3.jpg";
import foto4 from "../../assests/anasayfa/foto4.jpg";
import bg1 from "../../assests/anasayfa/bg1.webp";
import bg2 from "../../assests/anasayfa/bg2.webp";
import bg3 from "../../assests/anasayfa/bg3.webp";
import görüntü1 from "../../assests/anasayfa/görüntü1.webp";
import görüntü2 from "../../assests/anasayfa/görüntü2.webp";
import görüntü3 from "../../assests/anasayfa/görüntü3.webp";
import görüntü4 from "../../assests/anasayfa/görüntü4.webp";
import görüntü5 from "../../assests/anasayfa/görüntü5.webp";
import görüntü6 from "../../assests/anasayfa/görüntü6.webp";
import görüntü7 from "../../assests/anasayfa/görüntü7.webp";
import görüntü8 from "../../assests/anasayfa/görüntü8.webp";
import görüntü9 from "../../assests/anasayfa/görüntü9.webp";
import görüntü10 from "../../assests/anasayfa/görüntü10.webp";
import { motion, useInView } from "framer-motion";
import dogum from "../../assests/anasayfa/dogum.jpg";
import özel from "../../assests/anasayfa/özel.jpg";
import { Box } from "@mui/material";
import { Link } from "react-router-dom";
import SliderImage from "./SliderImage";

const images = [{ src: foto1 }, { src: foto3 }, { src: foto4 }];
const images2 = [
  { src: görüntü1 },
  { src: görüntü2 },
  { src: görüntü3 },
  { src: görüntü4 },
  { src: görüntü5 },
];

const images3 = [
  { src: görüntü6 },
  { src: görüntü7 },
  { src: görüntü8 },
  { src: görüntü9 },
  { src: görüntü10 },
  { src: görüntü1 },
  { src: görüntü2 },
  { src: görüntü3 },
  { src: görüntü4 },
  { src: görüntü5 },
];

const HomePage = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentIndex3, setCurrentIndex3] = useState(0);
  const [sliderPosition, setSliderPosition] = useState(50); // Orta noktada başlar
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen((prev) => !prev);
  };

  const handleMouseMove = (e) => {
    const { left, width } = e.currentTarget.getBoundingClientRect();
    const newPosition = ((e.clientX - left) / width) * 100;
    setSliderPosition(Math.max(0, Math.min(100, newPosition)));
  };

  const handleTouchMove = (e) => {
    const { left, width } = e.currentTarget.getBoundingClientRect();
    const touchX = e.touches[0].clientX;
    const newPosition = ((touchX - left) / width) * 100;
    setSliderPosition(Math.max(0, Math.min(100, newPosition)));
  };

  const nextImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const prevImage = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + images.length) % images.length
    );
  };

  const nextImage3 = () => {
    setCurrentIndex3((prevIndex) => (prevIndex + 1) % images3.length);
  };

  const prevImage3 = () => {
    setCurrentIndex3(
      (prevIndex) => (prevIndex - 1 + images3.length) % images3.length
    );
  };

  const goToImage = (index) => {
    setCurrentIndex(index);
  };
  const goToImage3 = (index) => {
    setCurrentIndex3(index);
  };

  useEffect(() => {
    const interval = setInterval(nextImage, 7000); // 7 saniyede bir fotoğraf değişimi
    return () => clearInterval(interval);
  }, []);

  const ref = useRef(null); // Referans oluştur
  const isInView = useInView(ref, { margin: "0px 0px -100px 0px" });
  const ref2 = useRef(null); // Referans oluştur
  const isInView2 = useInView(ref2, { margin: "0px 0px -100px 0px" });
  const ref3 = useRef(null); // Referans oluştur
  const isInView3 = useInView(ref3, { margin: "0px 0px -100px 0px" });
  const ref4 = useRef(null); // Referans oluştur
  const isInView4 = useInView(ref4, { margin: "0px 0px -100px 0px" });
console.log(menuOpen)
  return (
    <>
     {menuOpen ? null : <SliderImage />}
      <div className="slider-container">
        <Navbar menuOpen={menuOpen} setMenuOpen={setMenuOpen} toggleMenu={toggleMenu} />
        <button className="prev-button" onClick={prevImage}>
          ❮
        </button>
        <div className="image-wrapper">
          <img
            src={images[currentIndex].src}
            alt={images[currentIndex].alt}
            className="slider-image"
          />
        </div>
        <button className="next-button" onClick={nextImage}>
          ❯
        </button>
      </div>
      <div className="navigation-dots">
        {images.map((_, index) => (
          <div
            key={index}
            className={`dot ${currentIndex === index ? "active" : ""}`}
            onClick={() => goToImage(index)}
          ></div>
        ))}
      </div>

      <div
        style={{ display: "flex", justifyContent: "center", marginTop: "45px" }}
      >
        <p className="italic-text">Haydi sihir başlasın...</p>
      </div>
      <div className="slider-container1">
        <button className="prev-button" onClick={prevImage3}>
          ❮
        </button>
        <div className="image-wrapper">
          <img
            src={images3[currentIndex3].src}
            alt={images3[currentIndex3].alt}
            className="slider-image"
          />
        </div>
        <button className="next-button" onClick={nextImage3}>
          ❯
        </button>
      </div>
      <div className="navigation-dots">
        {images3.map((_, index) => (
          <div
            key={index}
            className={`dot ${currentIndex3 === index ? "active" : ""}`}
            onClick={() => goToImage3(index)}
          ></div>
        ))}
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Link to="/galeri" className="italic-text">
          Sihrin devamını görmek için{" "}
          <span
            style={{
              color: "blue",
              fontWeight: "bold",
              fontFamily: "Dancing Script, cursive",
              fontSize: window.innerWidth <= 768 ? "1.5rem" : "2.4rem",
            }}
          >
            tıklayınız
          </span>{" "}
          !!!
        </Link>
      </div>
      <div
        style={{ display: "flex", justifyContent: "center", marginTop: "25px" }}
      >
        <p className="italic-text">
          Mısırını al, içeceğini kap, eğlence seni bekliyor
        </p>
      </div>
      <div
        style={{
          height: window.innerWidth <= 768 ? 250 : 650,
          display: "flex",
          justifyContent: "center",
          marginBottom: "100px",
          marginTop: "40px",
        }}
      >
        {" "}
        <div
          style={{
            width: "95%",
            height: window.innerWidth <= 768 ? 250 : 650,
          }}
        >
          <div
            className="comparison-slider"
            onMouseMove={handleMouseMove}
            onTouchMove={handleTouchMove}
          >
            <div
              className="comparison-before"
              style={{ backgroundImage: `url(${bg1})` }}
            />
            <div
              className="comparison-after"
              style={{
                backgroundImage: `url(${bg3})`,
                clipPath: `polygon(0 0, ${sliderPosition}% 0, ${sliderPosition}% 100%, 0 100%)`,
              }}
            />
            <div
              className="slider-handle"
              style={{ left: `${sliderPosition}%` }}
            />
          </div>
        </div>
      </div>

      {/* <div class="content-container">
        <div class="text-section blue">
        <p className="italic-text">
          Minik Mucizeye Büyük Kutlama: Baby Shower ile Sevgi Dolu Anlar!
          </p>
        </div>
        <div class="image-section">
          <motion.div
            ref={ref}
            className="image-section"
            initial={{ opacity: 0, y: 50 }} // Başlangıç durumları
            animate={isInView ? { opacity: 1, y: 0 } : {}} // Görünürse animasyonu çalıştır
            transition={{ duration: 1.3 }}
          >
            <img src={baby1} alt="Fotoğraf" className="hover-effect" />
          </motion.div>
        </div>
      </div> */}
      <div class="content-container2 container-blue">
        <div class="image-section">
          <motion.div
            ref={ref2}
            className="image-section"
            initial={{ opacity: 0, x: -50 }} // Soldan sağa
            animate={isInView2 ? { opacity: 1, x: 0 } : {}}
            transition={{ duration: 1.3 }}
          >
            {" "}
            <img src={görüntü1} alt="Fotoğraf" />
          </motion.div>
        </div>
        <div class="text-section">
          <p className="italic-text">
            Hayalindeki Konsepti Gerçeğe Dönüştüren Parti: Sihir Başlasın!
          </p>
        </div>
      </div>
      <div class="content-container">
        <div class="text-section blue">
          <p className="italic-text">
            Pastanın Mumları Kadar Parlak Bir Gün Seninle Olsun!
          </p>
        </div>
        <div class="image-section">
          <motion.div
            ref={ref3}
            className="image-section"
            initial={{ opacity: 0, x: 50 }} // Sağdan sola
            animate={isInView3 ? { opacity: 1, x: 0 } : {}}
            transition={{ duration: 1.3 }}
          >
            <img src={dogum} alt="Fotoğraf" />
          </motion.div>
        </div>
      </div>
      <div class="content-container2 container-blue">
        <div class="image-section">
          <motion.div
            ref={ref4}
            className="image-section"
            initial={{ opacity: 0, y: 50 }} // Başlangıç durumları
            animate={isInView4 ? { opacity: 1, y: 0 } : {}} // Görünürse animasyonu çalıştır
            transition={{ duration: 1.3 }}
          >
            <img src={özel} alt="Fotoğraf" />
          </motion.div>
        </div>
        <div class="text-section pink">
          <p className="italic-text">
            Özel Anlar İçin Unutulmaz Etkinlikler: Her Detayda Fark Yaratıyoruz!
          </p>
        </div>
      </div>
      {/* <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <Box
          sx={{
            width: "95%",
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)", // 2 sütun
            gap: 2, // Görseller arasındaki boşluk
            "@media (max-width: 780px)": {
              gridTemplateColumns: "1fr", // 780px altında tek sütun
            },
            marginBottom: 4,
          }}
        >
          <img
            src={görüntü1}
            alt="Görsel 1"
            style={{
              width: "100%",
              height: "400px",
              filter: "grayscale(100%)",
            }}
          />
          <img
            src={görüntü2}
            alt="Görsel 2"
            style={{ width: "100%", height: "400px" }}
          />
          <img
            src={bg2}
            alt="Görsel 3"
            style={{ width: "100%", height: "400px" }}
          />
          <img
            src={görüntü4}
            alt="Görsel 4"
            style={{
              width: "100%",
              height: "400px",
              filter: "grayscale(100%)",
            }}
          />
        </Box>
      </Box> */}
      {/* <div className="slider-container1">
        <button className="prev-button" onClick={prevImage}>
          ❮
        </button>
        <div className="image-wrapper">
          <img
            src={images2[currentIndex].src}
            alt={images2[currentIndex].alt}
            className="slider-image"
          />
        </div>
        <button className="next-button" onClick={nextImage}>
          ❯
        </button>
      </div> */}
      {/* <div className="navigation-dots">
        {images2.map((_, index) => (
          <div
            key={index}
            className={`dot ${currentIndex === index ? "active" : ""}`}
            onClick={() => goToImage(index)}
          ></div>
        ))}
      </div> */}
      <Yorumlar />
    </>
  );
};

export default HomePage;
