import React, { useEffect, useState } from "react";
import "./ImageSlider.css";

const SliderImage = () => {
  const [overlayText, setOverlayText] = useState( "Sizin Yerinize Her Detayı Düşünen...");

  const texts = [
    "Büyük Küçük Herkes İçin Eğlencenin Sınırının Olmadığı...",
    "Sizin Kuaförden Çıkıp Katıldığınız...",
     "Sizin Yerinize Her Detayı Düşünen..."
  ];
  useEffect(() => {
    let currentIndex = 0;

    const changeText = () => {
      setOverlayText(texts[currentIndex]);
      currentIndex = (currentIndex + 1) % texts.length; // Yazıyı döngüsel olarak değiştirme
    };

    const interval = setInterval(changeText, 5000); // Her 3 saniyede bir yazıyı değiştir

    // Component unmount olduğunda interval'i temizle
    return () => clearInterval(interval);
  }, []);
  window.onscroll = function () {
    let overlay = document.getElementById("overlay");

    // Eğer scroll 50px'i geçerse, div'in opaklığını sıfırlayarak gizle
    if (
      document.body.scrollTop > 150 ||
      document.documentElement.scrollTop > 150
    ) {
      overlay.style.opacity = "0"; // Div'i gizler
    } else {
      overlay.style.opacity = "1"; // Div'i tekrar görünür yapar
    }
  };
  return (
    <div class="overlay" id="overlay">
      <p class="baslik1">Bir Parti Evi Düşünün...</p>
      <p>_______</p>
      <p class="baslik2">{overlayText}</p>
    </div>
  );
};

export default SliderImage;
