import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./navbarOther.css";
import logo from "../../../assests/logo.png";
import { FaInstagram } from "react-icons/fa";
import tasarım from "../../../assests/anasayfa/tasarım.mp4";

const NavbarOther = () => {
  const [menuOpen1, setMenuOpen1] = useState(false);

  const [isVideoOpen, setIsVideoOpen] = useState(false);

  const openVideo = () => setIsVideoOpen(true);
  const closeVideo = () => setIsVideoOpen(false);
  const toggleMenu = () => {
    setMenuOpen1((prev) => !prev);
  };
  return (
    <>
      <div className="banner-container">
        <div className="scrolling-text">
          <span>Simge Büyükgüzel Party House</span>
          <span>Her Yaşınız Bizimle Başlar Bizimle Biter...</span>
          <span>Simge Büyükgüzel Party House</span>
          <span>Her Yaşınız Bizimle Başlar Bizimle Biter...</span>
        </div>
      </div>
      <nav className={`menu1 ${menuOpen1 ? "open1" : ""}`}>
        <div className="gizli-div1">
          <div className="hamburger1" onClick={toggleMenu}>
            <div className="line1"></div>
            <div className="line1"></div>
            <div className="line1"></div>
          </div>
          <div>
            <Link to="/" className="menu-item22">
              <img
                src={logo}
                className={`${menuOpen1 ? "menu-icon22-test" : "menu-icon22"}`}
                alt="Logo"
              />
            </Link>
          </div>
          <div className="bos"></div>
        </div>

        <ul className="menu-list1">
          <Link to="/" className="menu-item1">
            Anasayfa
          </Link>
          <Link className="menu-item1" to="/galeri">
            Galeri
          </Link>
          <Link className="menu-item1" to="/hizmetlerimiz">
            Hizmetlerimiz
          </Link>
          <Link to="/" className="menu-item1">
            <img src={logo} className="menu-icon1" alt="test" />
          </Link>
          <li className="menu-item" onClick={openVideo}>
            Partilerden Kesitler
          </li>
          <Link className="menu-item1" to="/iletisim">
            İletişim
          </Link>
          <li className="menu-item1">
            <a
              href="https://www.instagram.com/simge_buyukguzel_party_house/"
              target="_blank"
              rel="noopener noreferrer"
              className="menu-item1"
            >
              <FaInstagram size={24} />
              Instagram
            </a>
          </li>
        </ul>
      </nav>
      {isVideoOpen && (
        <div className="video-modal">
          <div className="video-content">
            <button className="close-button" onClick={closeVideo}>
              &times;
            </button>
            <video width="100%" height="100%" controls>
              <source src={tasarım} type="video/mp4" />
              Tarayıcınız video etiketini desteklemiyor.
            </video>
          </div>
        </div>
      )}
    </>
  );
};

export default NavbarOther;
