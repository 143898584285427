import React from "react";
import { FaInstagram } from "react-icons/fa";
import './Footer.css'

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        {/* İletişim Bilgileri */}
        <div className="contact-info">
          <p>Telefon: +90 530 150 3780</p>
          <a href="mailto:simgebg@gmail.com">Email: simgebg@gmail.com</a>
          <p>
            Prof. Dr. Ahmet Taner Kışlalı Mahallesi 2864. Cadde, Park Cd.
            No:42/B, 06810 Çankaya
          </p>
        </div>

        {/* Sosyal Medya Bağlantıları */}
        <div className="social-media">
          <a
            href="https://www.instagram.com/simge_buyukguzel_party_house/"
            target="_blank"
            rel="noopener noreferrer"
            className="icon"
          >
            <FaInstagram size={25} />
            Instagram
          </a>
        </div>

        {/* Telif Hakkı */}
        <div className="copyright">
          © {new Date().getFullYear()} Tüm Hakları Saklıdır.
        </div>
      </div>
    </footer>
  );
};

export default Footer;
