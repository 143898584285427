import React, { useEffect, useState } from "react";
import NavbarOther from "../Navbar/PublicOther/NavbarOther";

const Contact = () => {
  const [fadeIn, setFadeIn] = useState(false);

  useEffect(() => {
    setFadeIn(true);
  }, []);

  return (
    <>
      <NavbarOther></NavbarOther>
      <div className="container mx-auto px-4 py-12">
        <h1
          style={{
            fontFamily: "'Dancing Script', cursive",
            fontSize: "2.25rem",
            fontWeight: "800",
            textAlign: "center",
            color: "rgb(75 85 99)",
            marginBottom: "1rem",
            transition: "all 1s",
            opacity: fadeIn ? "1" : "0",
            transform: fadeIn ? "translateY(0)" : "translateY(2.5rem)",
          }}
        >
          İletişim
        </h1>

        {/* Üst Kısım - İletişim Bilgileri Kartları */}
        <div
          className={`grid md:grid-cols-2 lg:grid-cols-4 gap-8 mb-12 transition-all duration-1000 ${
            fadeIn ? "opacity-100 translate-y-0" : "opacity-0 translate-y-10"
          }`}
        >
          <div className="bg-gray-50 shadow-lg rounded-lg p-6 transform transition duration-300 hover:scale-105 hover:shadow-2xl cursor-pointer">
            <h2  style={{
            fontFamily: "'Dancing Script', cursive",
            fontSize: "1.75rem",
            fontWeight: "800",
            textAlign: "center",
            color: "rgb(75 85 99)",
            marginBottom: "1rem",
            transition: "all 1s",
            opacity: fadeIn ? "1" : "0",
            transform: fadeIn ? "translateY(0)" : "translateY(2.5rem)",
          }}>
              Instagram
            </h2>
            <a
              target="_blank"
              href="https://www.instagram.com/simge_buyukguzel_party_house/"
            >
              Instagram adresine gitmek için tıklayınız.
            </a>
          </div>
          {/* Adres Kartı */}
          <div className="bg-gray-50 shadow-lg rounded-lg p-6 transform transition duration-300 hover:scale-105 hover:shadow-2xl cursor-pointer">
          <h2  style={{
            fontFamily: "'Dancing Script', cursive",
            fontSize: "1.75rem",
            fontWeight: "800",
            textAlign: "center",
            color: "rgb(75 85 99)",
            marginBottom: "1rem",
            transition: "all 1s",
            opacity: fadeIn ? "1" : "0",
            transform: fadeIn ? "translateY(0)" : "translateY(2.5rem)",
          }}>
              Telefon
            </h2>
            <p className="text-gray-800">
              <a
                href="tel:+905301503780"
                className="text-gray-600 transition duration-300 ease-in-out"
              >
                0530 150 37 80
              </a>
            </p>
          </div>

          <div className="bg-gray-50 shadow-lg rounded-lg p-6 transform transition duration-300 hover:scale-105 hover:shadow-2xl cursor-pointer">
          <h2  style={{
            fontFamily: "'Dancing Script', cursive",
            fontSize: "1.75rem",
            fontWeight: "800",
            textAlign: "center",
            color: "rgb(75 85 99)",
            marginBottom: "1rem",
            transition: "all 1s",
            opacity: fadeIn ? "1" : "0",
            transform: fadeIn ? "translateY(0)" : "translateY(2.5rem)",
          }}>Adres</h2>
            <a
              href="https://www.google.com/maps/place/Simge+B%C3%BCy%C3%BCkg%C3%BCzel+Party+House/@39.8688926,32.6791725,17z/data=!3m1!4b1!4m6!3m5!1s0x14d338c8c84d0bfd:0x843b2bf13d5d9c3!8m2!3d39.8688926!4d32.6791725!16s%2Fg%2F11g69g92s2?entry=ttu&g_ep=EgoyMDI0MTIxMS4wIKXMDSoASAFQAw%3D%3D"
              className="text-gray-800"
              target='_blank'
            >
              Prof. Dr. Ahmet Taner Kışlalı Mahallesi 2864. Cadde, Park Cd.
              No:42/B, 06810 Çankaya
            </a>
          </div>

          <div className="bg-gray-50 shadow-lg rounded-lg p-6 transform transition duration-300 hover:scale-105 hover:shadow-2xl cursor-pointer">
          <h2  style={{
            fontFamily: "'Dancing Script', cursive",
            fontSize: "1.75rem",
            fontWeight: "800",
            textAlign: "center",
            color: "rgb(75 85 99)",
            marginBottom: "1rem",
            transition: "all 1s",
            opacity: fadeIn ? "1" : "0",
            transform: fadeIn ? "translateY(0)" : "translateY(2.5rem)",
          }}>
              E-posta
            </h2>
            <p className="text-gray-800">
              <a
                href="mailto:simgebg@gmail.com"
                className="text-gray-500 transition duration-300 ease-in-out"
              >
                simgebg@gmail.com
              </a>
            </p>
          </div>
        </div>

        {/* Alt Kısım - Harita */}
        <div className="bg-white w-full shadow-xl rounded-lg overflow-hidden transform transition duration-300 hover:scale-105 hover:shadow-2xl cursor-pointer">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4330.673295050727!2d32.67524743025367!3d39.86867230805909!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14d338c8c84d0bfd%3A0x843b2bf13d5d9c3!2zU2ltZ2UgQsO8ecO8a2fDvHplbCBQYXJ0eSBIb3VzZQ!5e0!3m2!1str!2str!4v1733054181734!5m2!1str!2str"
            width="100%"
            height="350"
            style={{ border: 0 }}
            allowFullScreen
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            title="map"
          ></iframe>
        </div>

        {/* İletişim Formu Kartı */}
        {/* <div className="bg-blue-50 w-full shadow-xl rounded-lg p-4 mt-12 mx-auto max-w-4xl ">
          <h2 className="text-3xl font-semibold text-center text-pink-500 mb-6">
            Mesaj Gönderin
          </h2>
          <h3 className="text-xl text-center text-gray-600 mb-8">
            İletişim Formu
          </h3>
          <form className="space-y-6">
            <div className="flex justify-between">
              <div className="w-1/2 pr-2">
                <label
                  htmlFor="name"
                  className="block text-lg font-medium text-gray-700"
                >
                  Ad Soyad
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  className="w-full p-4 mt-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-pink-400"
                  required
                />
              </div>
              <div className="w-1/2 pl-2">
                <label
                  htmlFor="email"
                  className="block text-lg font-medium text-gray-700"
                >
                  E-posta
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  className="w-full p-4 mt-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-pink-400"
                  required
                />
              </div>
            </div>

            <div>
              <label
                htmlFor="message"
                className="block text-lg font-medium text-gray-700"
              >
                Mesaj
              </label>
              <textarea
                id="message"
                name="message"
                rows="6"
                className="w-full p-4 mt-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-pink-400"
                required
              ></textarea>
            </div>

            <button
              type="submit"
              className="w-full py-3 bg-pink-500 text-white font-semibold rounded-lg hover:bg-pink-600 focus:outline-none focus:ring-2 focus:ring-pink-400"
            >
              Gönder
            </button>
          </form>
        </div> */}
      </div>
    </>
  );
};

export default Contact;
